import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const WorkingFree = () => (
  <Layout>
    <SEO
      title="How Working For Free Can Be a Good Thing"
      description="It might sound counter intuitive but one of the quickest ways around this problem is to work for free. It works because it lets you ignore the cost part of the equation"
    />
    <h1>How Working For Free Can Be a Good Thing</h1>

    <p>When you are thinking of making some money outside of work or starting a business, one of the first hurdles you will reach is a question of confidence. You want to get paid but you probably don’t feel comfortable charging for your work yet.</p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/8C5dRHF.jpg"
        alt="how working for free can be a good thing"
        description="empty jar"
      />
    </div>
    <p>
      Photo by{" "} 
      <a
        href="https://unsplash.com/es/@dariomen"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
      Darío Méndez
      </a>
      {" "} on Unsplash
    </p>

    <p>It might sound counterintuitive but one of the quickest ways around this problem is to work for free. It works because it lets you ignore the cost part of the equation. You can focus on providing a great service but you don’t have to worry so much about time, unless it is sensitive to your industry.</p>
    <p>One of the benefits is that it is extremely easy to impress the client and exceed their expectations. Promise a service and if you do a great job and over-deliver they might end up paying you anyway. Value is closely linked to price psychologically, so the less someone pays for something relatively, the worse they expect it to be.</p>
    <p>Another benefit is that in the long run, if you keep the client, you will end up making a profit from them anyway. All the future jobs and recommendations they give you will more than cover that first job you did for free.</p>
    <p>Now, this doesn’t work for every service and if you do have upfront costs then I would recommend asking the client to at least cover your travel (if costly), additional equipment etc.</p>
    <p>Don’t think that free means you don’t have to produce quality work, because your reputation is still on the line and things can still go wrong for a client if you have access to sensitive information.</p>

    <h2>Disadvantages of working for free</h2>
    <p>I mentioned before how working for free might not be best for everyone because some industries have high costs to do a job and it wouldn’t make sense to absorb those costs yourself if it’s going to have a negative effect on your life. Let’s take building a house for a client as an example. If you make an average wage at your job then this wouldn’t make any sense at all, you couldn’t even build yourself a house at no loss. What if you earn £50m a year, this looks like a completely different equation now.</p>
    <p>That was a silly example but hopefully you can see how your financial situation effect the type of work you can do for free. Don’t put yourself into a hole to see the advantages of free work because you might not be able to take advantage of them if they do come with you being in financial ruin.</p>
    <p>Another disadvantage is that the client brings no value to you at all. It is quite hard to get no value at all because at the very least you can learn how things work. Beyond that, the work might not be suitable to put in your portfolio. The client might decide not to use it. They might not give you a good recommendation and they could decide to never use you again in the future. There are probably other things that can go wrong but you should take a measured approach to who you do work for.</p>
    <p>Nothing is free. Your time is worth something and the time you spend doing work and not billing for it is time you could have spent earning money somewhere else. The idea though is that the time spent working for free pays you back massively in the future. You are essentially investing in yourself and your future career.</p>

    <h2>How to transition from free to paid</h2>
    <p>So the plan has worked and you’ve managed to get some great experience working for different people. You should have figured out how long things take, what a client expects to see, how often you should communicate etc. You should have figured out exactly what type of service you want to provide too.</p>
    <p>I can imagine that before you start, you might have a list of services you want to cover. After working with a few people, you might realise that it makes much more sense to narrow your focus on the things that you are good at, want to get good, will be the most valuable in the future or any other reason.</p>
    <p>One of the ways you can approach charging for your work is to give a warning before you even start. You can be transparent and let the potential client know that right now you are charging nothing to gain experience and eventually that will change. If you have an idea of what you will charge, let them know. If not then you can say that you will figure it out. Either way, there will be no nasty surprises for the client when you decide to involve money.</p>
    <p>The opposite, of course, is to never bring it up. It’s probably not the best way to go about things but if money really is a problem area for you then completely ignoring it and any conversation around it might just work. Note that not everyone is as understanding as you might want them to be and when the subject eventually does come up then it could be a problem.</p>

    <h2>Other way</h2>
    <p>Working for free is not for everyone. For whatever reason, it might not fit your circumstances. Even if it does, you might find that it wasn’t the thing that was holding you back at all. So even after following the advice I set out above, you might be right back at square one. In that case, here’s some tips that will help you out;</p>
    <h3>get confidence in your abilities</h3>
    <p>It’s definitely easier than it sounds but you need to find a way to gain confidence in your skills. This way you won’t have imposter syndrome and you will feel that whoever makes use of your skills will get something great in return.</p>
    <h4>How to get confidence</h4>
    <p>Find a group of like minded individuals that you can bounce ideas off and help you organise your thinking. It only works if you respect their opinions. The positive feedback you get from them will give you a boost.</p>
    <h3>Build a social media following</h3>
    <p>Build a following showcasing your skills. Give advice away for free basically. If lots of people follow you based on what you are saying then you can deduce that your ideas are good, or at least worthy of attention.</p>


    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default WorkingFree
